import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

async function Arduino() {
  return new Promise((resolve, _) => {
    const loader = new GLTFLoader();
    loader.load(
      "/2023/arduino.glb",
      function (gltf) {
        const arduino = gltf.scene;
        resolve(arduino);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
}

export { Arduino };
