import { WebGLRenderer } from "three";

function createRenderer() {
  const renderer = new WebGLRenderer({
    antialias: true,
    precision: "highp",
    preserveDrawingBuffer: true,
  });
  renderer.useLegacyLights = true;

  return renderer;
}

export { createRenderer };
