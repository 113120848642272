<template>
  <div id="proto">
    <div id="proto-words">
      <h3>Proto Cooperative</h3>
      <p>
        Proto Cooperative is myself and another Pittsburgh based maker. We are
        interested in robotics, mid-scale manufacturing, audio equipment, and
        generative art, to name a few.
        <br />
        _______
        <br /><br />
        Our current focus is an Arduino based full stereo pedal switcher called
        <i>The Heighliner</i>. Link coming soon.
        <br />
        _______
        <br /><br />
        Other projects include: lathed part defect visualizer, object
        recognition assisted automatic soldering machine, and automatic wire
        cutter.
        <br />
        _______
        <br /><br />
        Always open to collaboration.
        <br />
        _______
        <br /><br />
        <a href=" mailto: protocooperative@gmail.com">Contact Us</a>
      </p>
    </div>
    <div id="proto-container"></div>
  </div>
</template>

<script>
import { BasicViewPort } from "@/World/components/basicView";
import { Arduino } from "@/World/models/arduino";
import { Points, PointsMaterial, Vector3 } from "three";

export default {
  name: "ProtoCoop",
  mounted() {
    const container = document.querySelector("#proto-container");
    this.viewPort = new BasicViewPort(container);
    const arduino = Arduino().then((obj) => {
      const arduino = obj.children[0];
      const arduinoPoints = new Points(
        arduino.geometry,
        new PointsMaterial({ size: 0.1, color: "white" })
      );
      arduinoPoints.setRotationFromAxisAngle(new Vector3(1, 0, 0), 1.5708);
      arduinoPoints.scale.set(0.05, 0.05, 0.05);

      this.viewPort.addToScene(arduinoPoints);
    });
    this.viewPort.start();
  },
};
</script>

<style scoped>
#proto {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: break-word;
}
a {
  color: red;
}

h3 {
  display: inline-block;
  border-bottom: 2px solid white;
  width: 100%;
  padding-left: 10px;
}

p {
  font-family: "Share Tech Mono", monospace;
  padding-left: 10px;
  padding-right: 10px;
}

#proto-words {
  height: 60%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: 0px;
}

#proto-words::-webkit-scrollbar {
  display: none;
}

#proto-container {
  border-top: 2px solid white;
  width: 100%;
  height: 40%;
}
</style>
