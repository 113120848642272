import { createCamera } from "@/World/components/camera";
import { createScene } from "@/World/components/scene";
import { createRenderer } from "@/World/system/renderer";
import { ViewPortResizer } from "@/World/system/ViewPortResizer";
import { Loop } from "@/World/system/Loop";
import { createOrbitControls } from "@/World/components/orbitController";
import { BoxGeometry, Color, Mesh, MeshBasicMaterial } from "three";

class BasicViewPort {
  renderer;
  camera;
  scene;
  loop;
  controls;

  constructor(container) {
    this.scene = createScene();
    this.scene.background = new Color("#212123");
    this.camera = createCamera(container);
    this.renderer = createRenderer();
    container.append(this.renderer.domElement);
    this.loop = new Loop(this.camera, this.scene, this.renderer);
    this.controls = createOrbitControls(this.camera, this.renderer.domElement);

    this.controls.enablePan = false;
    this.controls.enableRotate = true;
    this.controls.enableZoom = false;
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = -4;
    this.controls.maxDistance = 9;

    this.loop.updatables.push(this.controls);

    const resizer = new ViewPortResizer(container, this.camera, this.renderer);
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  start() {
    this.loop.start();
  }
  stop() {
    this.loop.stop();
  }

  addToScene(object) {
    this.scene.add(object);
  }
}

export { BasicViewPort };
