<template>
  <div id="galaxy-info-page">
    <h4>
      Higgs field phase local minimum achieved <br />
      stability potential: <i> unknown</i>
    </h4>
    <div id="higgs-diagram"></div>
    <h4>topological defects not detected</h4>
  </div>
</template>

<script>
import { BasicViewPort } from "@/World/components/basicView";
import { Color } from "three";
import { createHiggsDiagram } from "@/World/components/higgsField";

export default {
  name: "GalaxyInfo",
  mounted() {
    const container = document.querySelector("#higgs-diagram");
    this.viewPort = new BasicViewPort(container);
    const higgsField = createHiggsDiagram();
    this.viewPort.scene.add(higgsField);
    this.viewPort.controls.autoRotateSpeed = -1;
    this.viewPort.controls.autoRotate = false;
    this.viewPort.controls.maxDistance = 35;
    this.viewPort.start();
  },
};
</script>

<style scoped>
/* #galaxy-info-page {
  min-width: 400px;
} */

#higgs-diagram {
  aspect-ratio: 1/1;
}

h3,
h4 {
  font-family: "Share Tech Mono", monospace;
  display: inline-block;
  color: white;
  padding-left: 10px;
  text-align: left;
}

p {
  color: white;
  font-family: "Share Tech Mono", monospace;
}
</style>
