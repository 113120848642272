<template>
  <div>
    <nav-bar-2023 />
    <div id="scene-container">
      <button v-show="onFront" @click="descend()" id="descend-button">
        Descend
      </button>
    </div>
  </div>
</template>

<script>
import { World } from "@/World/World.js";
import { gsap } from "gsap";
import NavBar2023 from "@/components/2023NavBar.vue";

export default {
  name: "BlackHole",
  components: {
    NavBar2023,
  },
  data() {
    return {
      world: "Could not create world",
      onFront: true,
    };
  },
  mounted() {
    const container = document.querySelector("#scene-container");
    this.world = new World(container);
    this.world.start();
    this.world.generateUniverse();
  },

  methods: {
    descend() {
      this.world.descend("/2023/main");
      let tl = gsap.timeline();
      tl.add(() => {
        this.onFront = false;
      }, 0.33);
    },
    blink(event) {
      gsap.delayedCall(0.66, () => {
        event.target.blur();
      });
    },
  },
};
</script>

<style scoped>
#scene-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

#descend-button {
  top: 37.5%;
  left: 50%;
  border-color: white;
  font-family: "Fira Code", monospace;
  background-color: transparent;
  opacity: 60%;
  color: white;
  position: absolute;
  width: 130px;
  transform: translate(-50%, -50%);
  border-color: white;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

button:focus {
  animation: blinker 0.11s linear infinite;
}

#descend-button:hover {
  opacity: 80%;
}
</style>
