<template>
  <div id="about-me">
    <div id="about-words">
      <h3>About Me</h3>
      <p>
        Pittsburgh based Full Stack Developer and Food Scientist with creative
        background.
      </p>
      <p>_______</p>
      <p>
        Currently assisting teaching full stack web development with SpringBoot,
        .NET, PostgreSQL, MsSQL, and Vue.js. Expertise and research in craft
        chocolate and coffee.
      </p>
      <p>_______</p>
      <p>
        Building games with Garden Games in Unity. Building audio hardware and
        mid-scale manufacturing projects with Proto Cooperative.
      </p>
      <p>_______</p>
      <p>Part time interested in: Arduino • Unity • three.js • robotics</p>
      <p>Full time goblin.</p>
      <p>Sort of obsessed with cosmology.</p>
    </div>
    <div id="view-container"></div>
  </div>
</template>

<script>
import { BasicViewPort } from "@/World/components/basicView";
import { Face } from "@/World/models/face";
import { Mesh, PointsMaterial, MeshBasicMaterial } from "three";

export default {
  name: "AboutMe",
  data() {
    return {
      viewPort: "Failed To Load",
    };
  },
  mounted() {
    const container = document.querySelector("#view-container");
    this.viewPort = new BasicViewPort(container);
    Face().then((obj) => {
      const faceObj = obj.children[0];
      const facePoints = new Mesh(
        faceObj.geometry,
        new MeshBasicMaterial({ size: 0.1, color: "white", wireframe: true })
      );
      facePoints.scale.set(0.5, 0.5, 0.5);
      this.viewPort.addToScene(facePoints);
    });
    this.viewPort.start();
  },
};
</script>

<style scoped>
#about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: break-word;
}

h3 {
  display: inline-block;
  border-bottom: 2px solid white;
  width: 100%;
  padding-left: 10px;
}

p {
  font-family: "Share Tech Mono", monospace;
  padding-left: 10px;
  padding-right: 10px;
}

#about-words {
  height: 60%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: 0px;
}

#about-words::-webkit-scrollbar {
  display: none;
}

#view-container {
  border-top: 2px solid white;
  width: 100%;
  height: 40%;
}
</style>
