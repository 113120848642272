<template>
  <div id="navRoot">
    <div id="nav-bar" @focus="blink($event)">
      <a
        v-show="$route.name != 'home'"
        href=""
        @focus="blink($event)"
        @click.prevent="pushAboutMe($event)"
        id="about-me-nav"
      >
        about.me</a
      >
      <a
        v-show="$route.name != 'home'"
        href=""
        @focus="blink($event)"
        @click.prevent="pushProto($event)"
        id="proto-nav"
      >
        proto.co-op</a
      >
      <a
        v-show="$route.name != 'home'"
        href=""
        @focus="blink($event)"
        @click.prevent="pushGarden($event)"
        id="games-nav"
      >
        garden.games</a
      >
      <a><br /></a>
      <a id="github-link" href="https://github.com/PatrickDolanJ">github</a>
      <a id="my-name" href="" @focus="blink($event)" @click.prevent="pushMain">
        patrick.dolan.2023</a
      >
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { Arduino } from "../World/models/arduino";
import { Points, PointsMaterial, Vector3 } from "three";

export default {
  name: "NavBar2023",
  mounted() {
    this.loadModels();
  },
  methods: {
    blink(event) {
      gsap.delayedCall(0.66, () => {
        event.target.blur();
      });
    },
    pushMain(event) {
      if (this.$route.name != "home" && this.$route.path != "/2023/main") {
        this.$router.push("/2023/main");
      }
    },
    pushAboutMe() {
      if (this.$route.path != "/2023/about-me") {
        this.$router.push("/2023/about-me");
      }
    },
    pushProto(event) {
      if (this.$route.path != "/2023/proto") {
        this.$router.push("/2023/proto");
      }
    },
    pushGarden(event) {
      if (this.$route.path != "/2023/garden-games") {
        this.$router.push("/2023/garden-games");
      }
    },

    loadModels() {
      const arduino = Arduino().then((obj) => {
        const arduino = obj.children[0];
        const arduinoPoints = new Points(
          arduino.geometry,
          new PointsMaterial({ size: 0.01, color: "white" })
        );
        arduinoPoints.setRotationFromAxisAngle(new Vector3(1, 0, 0), 1.5708);
        arduinoPoints.scale.set(0.05, 0.05, 0.05);
      });
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  background-color: #212123;
}

a:focus,
button:focus {
  animation: blinker 0.11s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

button:hover {
  opacity: 80%;
}

#nav-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  bottom: 2%;
  left: 1.5%;
}

#my-name {
  z-index: 1;
  color: white;
  font-family: "Share Tech Mono", monospace;
  opacity: 80%;
}

#github-link {
  color: red;
}

a {
  display: inline-block;
  z-index: 1;
  color: white;
  font-family: "Share Tech Mono", monospace;
  opacity: 80%;
  width: fit-content;
}
</style>
