<template>
  <div id="app">
    <router-view />
    <div id="archive-holder" v-if="$route.path == '/'">
      <h2>2025 coming soon</h2>
      <a href="" @click.prevent="push2023Archive($event)"> 2023 </a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "patrick.dolan";
  },
  methods: {
    push2023Archive(event) {
      this.$router.push("/2023");
    },
  },
};
</script>

<style scoped>
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

#app {
  width: 100%;
  height: 100%;
}

a {
  color: black;
  font-size: 40px;
  text-decoration: none;
}

#archive-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
</style>
