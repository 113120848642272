<template>
  <div id="games-page">
    <div id="game-words">
      <h3>Garden Games</h3>
      <p>
        Garden Games is a small indie game dev group casually working on small
        games focused on procedural generation, physics, and accessibility.
        <br />
        _______
        <br /><br />
        Mostly working in Unity.
        <br />
        _______
        <br /><br />
        Games include: small golf + <i> Katamari Damaci</i> game about cleaning
        up space junk, roguelite procedural cave exploring survival horror, and
        several game jam projects
        <br />
        _______
        <br /><br />
        Link coming soon.
      </p>
    </div>
    <div id="games-container"></div>
  </div>
</template>

<script>
import { BasicViewPort } from "@/World/components/basicView";
import { UnityLogo } from "@/World/models/unity";
import { MeshBasicMaterial, Mesh, Vector3 } from "three";

export default {
  name: "GardenGames",
  mounted() {
    const container = document.querySelector("#games-container");
    this.viewPort = new BasicViewPort(container);
    const unityLogo = UnityLogo().then((obj) => {
      const unity = obj.children[0];
      const unityObj = new Mesh(unity.geometry, new MeshBasicMaterial());
      unityObj.scale.set(0.15, 0.15, 0.15);
      unityObj.position.set(0, -0.5, 0);
      this.viewPort.addToScene(unityObj);
    });
    this.viewPort.start();
  },
};
</script>

<style scoped>
#games-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: break-word;
}

h3 {
  display: inline-block;
  border-bottom: 2px solid white;
  width: 100%;
  padding-left: 10px;
}

p {
  font-family: "Share Tech Mono", monospace;
  padding-left: 10px;
  padding-right: 10px;
}

#game-words {
  height: 60%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: 0px;
}

#game-words::-webkit-scrollbar {
  display: none;
}

#games-container {
  border-top: 2px solid white;
  width: 100%;
  height: 39%;
}
</style>
