import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MainPage from "../views/MainPageView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/2023",
    name: "home",
    component: HomeView,
  },
  {
    path: "/2023/main",
    alias: ["/2023/proto", "/2023/about-me", "/2023/garden-games"],
    name: "main",
    component: MainPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (from.name != "home" && to.name != "home") {
    router.app.$emit("random-point");
    console.log("Home -> home");
  }
  next();
});

export default router;
