<template>
  <div>
    <nav-bar2023 />
    <div @random-point="randomPoint($event)" id="main-container">
      <div id="scene-container"></div>
      <galaxy-info v-if="$route.path != '/2023/main'"></galaxy-info>
      <about-me id="about-me" v-if="$route.path == '/2023/about-me'"></about-me>
      <proto-coop id="proto" v-if="$route.path == '/2023/proto'"></proto-coop>
      <garden-games
        id="games"
        v-if="$route.path == '/2023/garden-games'"
      ></garden-games>
    </div>
  </div>
</template>

<script>
import { World } from "@/World/World";
import { gsap } from "gsap";
import AboutMe from "@/components/AboutMe.vue";
import ProtoCoop from "./ProtoCoop.vue";
import GardenGames from "./GardenGames.vue";
import GalaxyInfo from "./GalaxyInfo.vue";
import NavBar2023 from "./2023NavBar.vue";

export default {
  name: "MainPage",
  components: {
    AboutMe,
    ProtoCoop,
    GardenGames,
    GalaxyInfo,
    NavBar2023,
  },
  data() {
    return {
      world: "Failed To Load",
    };
  },
  mounted() {
    this.$root.$on("random-point", this.randomPoint);
    const container = document.querySelector("#scene-container");
    this.world = new World(container);
    this.world.start();
    if (this.$store.getters.getUniverse != null) {
      this.world.setUniverse(this.$store.getters.getUniverse);
    } else {
      this.world.generateUniverse();
    }
    this.world.setMainPage();
  },
  beforeDestroy() {
    this.$root.$off("random-point", this.randomPoint);
  },
  methods: {
    randomPoint(event) {
      gsap.delayedCall(0.33, () => {
        if (event) {
          event.target.blur();
        }
        this.world.goToPoint();
      });
    },
  },
};
</script>

<style scoped>
#scene-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #212123;
}

#about-me,
#proto,
#games {
  font-family: "Fira Code", monospace;
  opacity: 60%;
  background-color: #212123;
  border-color: white;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  color: white;
}

#galaxy-info-page {
  top: 5vh;
  left: 2vw;
  border-width: 2px;
  opacity: 0.6;
  border-style: solid;
  border-color: white;
  width: 20vw;
  height: fit-content;
  z-index: 1;
}

#about-me:hover,
#proto:hover,
#games:hover,
#galaxy-info-page:hover {
  opacity: 80%;
}

#main-container {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

/* DESKTOP */
@media (min-width: 991px) {
  #about-me,
  #games,
  #proto {
    right: 2vw;
    font-size: 14px;
    height: 85vh;
    max-width: 25%;
  }
}

/* MOBILE */
@media (max-width: 992px) and (min-width: 376px) {
  #about-me,
  #games,
  #proto {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212123;
    height: 80vh;
    font-size: 12px;
    width: 85vw;
    z-index: 0;
  }

  #galaxy-info-page {
    display: none;
  }

  #main-container {
    justify-content: center;
  }
}

@media (max-width: 375px) {
  #about-me,
  #games,
  #proto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #212123;
    height: 80vh;
    font-size: 12px;
    width: 85vw;
    z-index: 1;
  }

  #galaxy-info-page {
    display: none;
  }
}
</style>
